import React, { useState } from "react"
import { graphql } from "gatsby"
import { LocalizedLink, useLocalization } from "gatsby-theme-i18n"
import { localizedPath } from "utils/helpers"
import TaxonomyTerm from "components/system/taxonomy-term"
import Layout from "components/layout"
import SEO from "components/seo"
import { Wysiwyg, Share, Media } from "components/helpers"
import { getSrc } from "gatsby-plugin-image"
import loup from "../images/loup.webp"
import vague from "../images/vague.webp"

import "scss/blog.scss"
import { FormattedMessage } from "react-intl"

const ArticleTemmplate = ({ data }) => {
  const { locale, defaultLang } = useLocalization()
  const [searchText, setSearchText] = useState("")

  const {
    site: {
      siteMetadata: { baseUrl },
    },

    nodeBlog: {
      id,
      title,
      path,
      field_chapo,
      body,
      relationships,
      field_blog_image,
      created,
      field_auteur,
    },
    allTaxonomyTermCategories,
  } = data

  const metadata = {
    url: path?.alias,
    title: title,
    description: field_chapo,
    image: getSrc(
      relationships?.field_blog_image.relationships?.field_media_image.localFile
    ),
  }

  return (
    <Layout>
      <SEO {...metadata} />
      <div className="header-blue">
        <div className="container article-blog">
          <div className="blog__header">
            <h1>
              <span>Blog</span>
            </h1>
            <span
              className="overline"
              style={{
                backgroundImage: `url(${vague})`,
              }}
            ></span>
          </div>
          <div className="case__breadcrumb__blog">
            <LocalizedLink to="/blog">Blog</LocalizedLink>
            <span className="case__separator"> > </span>
            {relationships.field_categorie?.map(({ id, name, path }) => (
              <div key={id} className="detail-cat-arian">
                <LocalizedLink to={path.alias}>{name}</LocalizedLink>
                <span className="case__separator"> / </span>
              </div>
            ))}
            <span className="case__separator"> > </span>
            <LocalizedLink to={path.alias}>{title}</LocalizedLink>
          </div>
          <div className="listing-blog">
            <div className="listing-blog__filtres">
              <form action="/search" method="get">
                <input
                  type="text"
                  name="q"
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                  placeholder="Découvrez des actualités, des articles..."
                />
                <button
                  type="submit"
                  className="icon-loup"
                  aria-label="icon-loup"
                  style={{
                    backgroundImage: `url(${loup})`,
                  }}
                />
              </form>
              <div className="listing-blog__filtres--title">Categorie</div>
              {allTaxonomyTermCategories.edges.map(
                ({ node: { id, name, path } }) => (
                  <TaxonomyTerm key={id} name={name} path={path} />
                )
              )}
            </div>
            <div className="listing-blog__articles">
              <div className="breadcrumb__blog__mobile case__breadcrumb__blog">
                <LocalizedLink to="/blog">Blog</LocalizedLink>
                <span className="case__separator"> > </span>
                {relationships.field_categorie?.map(({ id, name, path }) => (
                  <div key={id} className="detail-cat-arian">
                    <LocalizedLink to={path.alias}>{name}</LocalizedLink>
                    <span className="case__separator"> / </span>
                  </div>
                ))}
                <span className="case__separator"> > </span>
                <LocalizedLink to={path.alias}>{title}</LocalizedLink>
              </div>
              <div className="shadow-box">
                {relationships?.field_blog_image && (
                  <Media
                    className="article__thumb"
                    field_image_media={relationships.field_blog_image}
                  />
                )}
                {field_blog_image && (
                  <img
                    className="article__thumb"
                    src={`${baseUrl}/${field_blog_image?.field_media_image?.uri?.url}`}
                    alt=""
                  />
                )}
                <div className="date">{created}</div>
                <div className="articles articles__text">
                  <h2 className="articles__title">{title}</h2>
                  <Wysiwyg
                    processed={body.processed.replace(
                      /\/sites/g,
                      `${baseUrl}/sites`
                    )}
                  />
                  <div className="articles__rs article-author">
                    {field_auteur && (
                      <div className="author">
                        <FormattedMessage id="Write by" />: {field_auteur}
                      </div>
                    )}
                    <div className="articles__share">
                      <Share
                        url={localizedPath(
                          defaultLang,
                          locale,
                          `${path.alias}`
                        )}
                        title={`${title} | Blog Fullwave`}
                        subject={`${title} | Blog Fullwave`}
                        via={`fullwaveagency`}
                      />
                    </div>
                  </div>
                  {relationships.field_tag?.length > 0 && (
                    <div className="post-tags">
                      {relationships.field_tag?.map(({ id, name, path }) => (
                        <div key={id}>
                          <LocalizedLink to={path.alias}>{name}</LocalizedLink>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ArticleTemmplate

export const query = graphql`
  query articleTemplate($entityID: String!) {
    site {
      siteMetadata {
        baseUrl
      }
    }
    allTaxonomyTermCategories {
      edges {
        node {
          ...TaxonomyTermCategories
        }
      }
    }
    nodeBlog(id: { eq: $entityID }) {
      id
      title
      field_auteur
      field_chapo
      path {
        alias
      }
      body {
        processed
      }
      created(formatString: "DD/MM/YYYY")
      relationships {
        field_tag {
          id
          name
          path {
            alias
          }
        }
        field_categorie {
          id
          name
          path {
            alias
          }
        }
        field_blog_image {
          field_media_image {
            alt
          }
          relationships {
            field_media_image {
              localFile {
                extension
                publicURL
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
            }
          }
        }
      }
    }
  }
`
